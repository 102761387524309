import React from "react";
import { Redirect } from "react-router-dom";
import { reverse } from "named-urls";
// import { history } from "react-router-dom";

import {
  CLIENTS_APP_DOMAIN,
  WEBSITE_DOMAIN,
  TEMPLATES_DOMAIN
} from "../services/endpoints";
// import { getApplicationLocale } from "../services/localizationService";
// import en from "../translations/en.json";
// import mk from "../translations/mk.json";

import HomePage from "../pages/Home";
import {
  //DomainsPage,
  DomainsRegisterPage,
  DomainsTransferPage,
  DomainsNewTLDsPage,
  DomainsBulkOptions,
  DomainsWhoIs,
  DomainsPricesPage
} from "../pages/Domains";
import {
  HostingWebPage,
  HostingWindowsPage,
  HostingWordpressPage,
  HostingBusinessPage,
  HostingDirectAdminPage
} from "../pages/Hosting";
import { ServersDedicatedPage, ServersCloudPage } from "../pages/Servers";
import {
  WebDesignPersonalizedPage,
  WebDesignPremiumPage,
} from "../pages/WebDesign";
import ManagedCloud from "../pages/WebDesign/ManagedCloud";
import { AddonsPremiumSupportPage, AddonsSSLPage } from "../pages/Addons";
import {
  // OtherPage,
  OtherCareersPage,
  OtherLegalPage,
  OtherKnowledgeBasePage,
  OtherKnowledgeBaseCategoryPage,
  OtherKnowledgeBaseArticlePage,
  OtherAboutUsPage,
  OtherJobPostPage,
  OtherBrandPage,
  OtherContactPage,
  OtherControlPanelPage,
  OtherFirstWebsitePage,
  // OtherYoungEnterpreneursPage,
  // OtherFreeEbookPage,
  // OtherFreeEbookThankYouPage,
  OtherHostingCloudConsultationPage,
  OtherWebinarFirstWebsitePage,
  // OtherWebinarFirstWebsiteThankYouPage
  OtherWebSolution
} from "../pages/Other";

import {
  AndroidPage,
  // AndroidPopStepsPage,
  // AndroidImapStepsPage,
  OutlookPage,
  ThunderbirdPage,
  MacOsPage,
  AllChoices,
  AndroidPagePop,
  AndroidPageImap,
  OutlookPagePop,
  OutlookPageImap,
  ThunderbirdPagePop,
  ThunderbirdPageImap,
  IosPage,
  // IosStepsPage,
  IosPopPage,
  IosImapPage,
  // MacOsStepsPage,
  MacOsImapPage,
  MacOsPopPage,
  // OutlookPopStepsPage,
  // OutlookImapStepsPage,
  // ThunderbirdPopStepsPage,
  // ThunderbirdImapStepsPage,
  ErrorPage
} from "../pages/Other/EmailTutorials";

import TicketCreated from "../pages/TicketCreated";
import OfferSent from "../pages/OfferSent";
import WinPrizeSent from "../pages/WinPrizeSent";
// import Page404 from "../pages/Page404";
import OtherHostingCloudConsultationThankYouPage from "../pages/Other/OtherHostingCloudConsultationThankYouPage";
import OtherWebinarFirstWebsiteThankYouPage from "../pages/Other/OtherWebinarFirstWebsiteThankYouPage";
import WebSolutionSent from "../pages/WebSolutionSent";
import AWSCloudLandingPage from "../pages/Addons/AWSCloudLandingPage";
import ReferralLandingPage from "../pages/Other/ReferralLandingPage";
import WebDesignOnlineShop from "../pages/WebDesign/OnlineShop/OnlineShopPage";

export const ROUTE_HOME = window.location.pathname.startsWith("/en")
  ? "/en/"
  : "/mk/";

export const ROUTE_DOMAINS = `${ROUTE_HOME}domains`;
export const ROUTE_HOSTING = `${ROUTE_HOME}hosting`;
export const ROUTE_SERVERS = `${ROUTE_HOME}servers`;
export const ROUTE_CLOUD_SOLUTIONS = `${ROUTE_HOME}solutions`;
export const ROUTE_WEB_DESIGN = `${ROUTE_HOME}web-design`;
export const ROUTE_ADDONS = `${ROUTE_HOME}addons`;
export const ROUTE_OTHER = `${ROUTE_HOME}other`;
export const ROUTE_AWS_SERVICES = `${ROUTE_HOME}aws-cloud-services/`;

export const ROUTE_DOMAINS_REGISTER = `${ROUTE_DOMAINS}/register/:name?`;
export const ROUTE_DOMAINS_REGISTER_WITHOUT_PARAMS = `${ROUTE_DOMAINS}/register/`;
export const ROUTE_DOMAINS_TRANSFER = `${ROUTE_DOMAINS}/transfer/:name?`;
// export const ROUTE_DOMAINS_TRANSFER_WITHOUT_PARAMS = `${ROUTE_DOMAINS}/transfer`;
export const ROUTE_DOMAINS_PRICES = `${ROUTE_DOMAINS}/prices/:category?`;
// export const ROUTE_DOMAINS_PRICES_WITHOUT_PARAMS = `${ROUTE_DOMAINS}/prices`;
export const ROUTE_DOMAINS_NEW_TLDS = `${ROUTE_DOMAINS}/tlds/`;
export const ROUTE_DOMAINS_BULK = `${ROUTE_DOMAINS}/bulk/`;
export const ROUTE_DOMAINS_WHOIS = `${ROUTE_DOMAINS}/whois/:name?`;
// export const ROUTE_DOMAINS_WHOIS_WITHOUT_PARAMS = `${ROUTE_DOMAINS}/whois`;

export const ROUTE_HOSTING_WEB_HOSTING = `${ROUTE_HOSTING}/web-hosting/`;
export const ROUTE_HOSTING_WINDOWS_HOSTING = `${ROUTE_HOSTING}/windows-hosting/`;
export const ROUTE_HOSTING_BUSINESS_HOSTING = `${ROUTE_HOSTING}/business-hosting/`;
export const ROUTE_HOSTING_WORDPRESS_HOSTING = `${ROUTE_HOSTING}/wordpress-hosting/`;
export const ROUTE_HOSTING_DIRECTADMIN_HOSTING = `${ROUTE_HOSTING}/directadmin-hosting/`;

export const ROUTE_SERVERS_DEDICATED = `${ROUTE_SERVERS}/dedicated-servers/`;

export const ROUTE_CLOUD_SOLUTIONS_MANAGED_CLOUD = `${ROUTE_CLOUD_SOLUTIONS}/managed-cloud/`;

export const ROUTE_WEB_DESIGN_PREMIUM = `${ROUTE_WEB_DESIGN}/premium/`;
export const ROUTE_WEB_DESIGN_PERSONALIZED = `${ROUTE_WEB_DESIGN}/personalized/`;
export const ROUTE_WEB_DESIGN_ONLINE_SHOP = `${ROUTE_WEB_DESIGN}/online-shop/`;

export const ROUTE_ADDONS_SLL = `${ROUTE_ADDONS}/ssl/`;
export const ROUTE_ADDONS_SUPPORT = `${ROUTE_ADDONS}/support/`;

export const ROUTE_OTHER_ABOUT_US = `${ROUTE_OTHER}/about-us/`;
export const ROUTE_OTHER_OUR_BRAND = `${ROUTE_OTHER}/our-brand/`;
export const ROUTE_OTHER_CONTACT = `${ROUTE_OTHER}/contact/`;
export const ROUTE_OTHER_LEGAL = `${ROUTE_OTHER}/legal/`;
export const ROUTE_OTHER_REFERRAL = `${ROUTE_OTHER}/referral/`;

export const ROUTE_OTHER_CAREERS = `${ROUTE_OTHER}/careers/`;
export const ROUTE_OTHER_WEB_SOLUTION = `${ROUTE_OTHER}/web-solution/`;
export const ROUTE_OTHER_CAREERS_JOB_POST = `${ROUTE_OTHER_CAREERS}:post?`;

export const ROUTE_OTHER_BLOG = `${ROUTE_OTHER}/blog`;

export const ROUTE_OTHER_KNOWLEDGEBASE = `${ROUTE_OTHER}/knowledgebase/`;
export const ROUTE_OTHER_KNOWLEDGEBASE_CATEGORY = `${ROUTE_OTHER_KNOWLEDGEBASE}category/:id`;
export const ROUTE_OTHER_KNOWLEDGEBASE_ARTICLE = `${ROUTE_OTHER_KNOWLEDGEBASE}:id/`;

export const ROUTE_OTHER_CONTROL_PANEL = `${ROUTE_OTHER}/control-panel/`;
export const ROUTE_OTHER_FIRST_WEBSITE = `${ROUTE_OTHER}/first-website/`;
export const ROUTE_OTHER_PREMIUM = `${ROUTE_OTHER}/premium/`;
export const ROUTE_TICKET_CREATED = `${ROUTE_HOME}ticket/created/`;
export const ROUTE_OFFER_SENT = `${ROUTE_HOME}offer/sent/`;
export const ROUTE_WEBSOLUTION_SENT = `${ROUTE_HOME}websolution/sent/`;

export const ROUTE_OTHER_YOUNG_ENTERPRENEURS = `${ROUTE_OTHER}/young-enterpreneurs/`;
export const ROUTE_OTHER_FREE_EBOOK = `${ROUTE_OTHER}/ebook-website-launch/`;
export const ROUTE_OTHER_FREE_EBOOK_THANK_YOU = `${ROUTE_OTHER}/ebook-website-launch/thankyou/`;
export const ROUTE_PRIZE_SENT = `${ROUTE_HOME}win-prize/sent/`;

export const ROUTE_OTHER_HOSTING_CLOUD_CONSULTATION = `${ROUTE_OTHER}/hosting-cloud-consultation/`;
export const ROUTE_OTHER_HOSTING_CLOUD_CONSULTATION_THANK_YOU = `${ROUTE_OTHER}/hosting-cloud-consultation/thankyou/`;

export const ROUTE_OTHER_WEBINAR_FIRST_WEBSITE = `${ROUTE_OTHER}/webinar-first-website/`;
export const ROUTE_OTHER_WEBINAR_FIRST_WEBSITE_THANK_YOU = `${ROUTE_OTHER}/webinar-first-website/thankyou/`;

export const ROUTE_EMAIL_TUTORIALS = `${ROUTE_OTHER}/email/`;
export const ROUTE_EMAIL_TUTORIALS_ANDROID = `${ROUTE_OTHER}/email/android/`;
export const ROUTE_EMAIL_TUTORIALS_OUTLOOK = `${ROUTE_OTHER}/email/outlook/`;
export const ROUTE_EMAIL_TUTORIALS_THUNDERBIRD = `${ROUTE_OTHER}/email/thunderbird/`;
export const ROUTE_EMAIL_TUTORIALS_MACOS = `${ROUTE_OTHER}/email/macos/`;
export const ROUTE_EMAIL_TUTORIALS_IOS = `${ROUTE_OTHER}/email/ios/`;

export const ROUTE_EMAIL_TUTORIALS_ANDROID_IMAP = `${ROUTE_EMAIL_TUTORIALS_ANDROID}imap/`;
export const ROUTE_EMAIL_TUTORIALS_ANDROID_POP = `${ROUTE_EMAIL_TUTORIALS_ANDROID}pop/`;

export const ROUTE_EMAIL_TUTORIALS_OUTLOOK_IMAP = `${ROUTE_EMAIL_TUTORIALS_OUTLOOK}imap/`;
export const ROUTE_EMAIL_TUTORIALS_OUTLOOK_POP = `${ROUTE_EMAIL_TUTORIALS_OUTLOOK}pop/`;

export const ROUTE_EMAIL_TUTORIALS_THUNDERBIRD_IMAP = `${ROUTE_EMAIL_TUTORIALS_THUNDERBIRD}imap/`;
export const ROUTE_EMAIL_TUTORIALS_THUNDERBIRD_POP = `${ROUTE_EMAIL_TUTORIALS_THUNDERBIRD}pop/`;

export const ROUTE_EMAIL_TUTORIALS_MACOS_IMAP = `${ROUTE_EMAIL_TUTORIALS_MACOS}imap/`;
export const ROUTE_EMAIL_TUTORIALS_MACOS_POP = `${ROUTE_EMAIL_TUTORIALS_MACOS}pop/`;

export const ROUTE_EMAIL_TUTORIALS_IOS_IMAP = `${ROUTE_EMAIL_TUTORIALS_IOS}imap/`;
export const ROUTE_EMAIL_TUTORIALS_IOS_POP = `${ROUTE_EMAIL_TUTORIALS_IOS}pop/`;

// export const ROUTE_EMAIL_TUTORIALS_ANDROID_POP_STEPS = `${ROUTE_OTHER}/email/AndroidPopStepsPage`;
// export const ROUTE_EMAIL_TUTORIALS_ANDROID_IMAP_STEPS = `${ROUTE_OTHER}/email/AndroidImapStepsPage`;
// export const ROUTE_EMAIL_TUTORIALS_IOS_STEPS = `${ROUTE_OTHER}/email/IosStepsPage`;
// export const ROUTE_EMAIL_TUTORIALS_MACOS_STEPS = `${ROUTE_OTHER}/email/MacosStepsPage`;
// export const ROUTE_EMAIL_TUTORIALS_OUTLOOK_POP_STEPS = `${ROUTE_OTHER}/email/OutlookPopStepsPage`;
// export const ROUTE_EMAIL_TUTORIALS_OUTLOOK_IMAP_STEPS = `${ROUTE_OTHER}/email/OutlookImapStepsPage`;
// export const ROUTE_EMAIL_TUTORIALS_THUNDERBIRD_POP_STEPS = `${ROUTE_OTHER}/email/ThunderbirdPopStepsPage`;
// export const ROUTE_EMAIL_TUTORIALS_THUNDERBIRD_IMAP_STEPS = `${ROUTE_OTHER}/email/ThunderbirdImapStepsPage`;
export const ROUTE_EMAIL_TUTORIALS_ERROR = `${ROUTE_OTHER}/email/error/`;

export const ROUTE_404 = `${ROUTE_HOME}404/`;

export const ROUTE_CART = `/cart/`;
export const ROUTE_CLIENT_CART = `${CLIENTS_APP_DOMAIN}/cart/`;
export const ROUTE_CLIENT_LOGIN = `${CLIENTS_APP_DOMAIN}/login/`;
export const ROUTE_CLIENT_REFERRAL = `${CLIENTS_APP_DOMAIN}/referral-program/code`;
export const ROUTE_WEBSITE_DOMAIN = WEBSITE_DOMAIN;
export const ROUTE_TEMPLATES_DOMAIN = TEMPLATES_DOMAIN;

export const homeRoute = {
  path: ROUTE_HOME,
  exact: true,
  component: HomePage
};

// These appear in the navbar and footer
export const routes = [
  {
    path: ROUTE_DOMAINS,
    exact: true,
    title: "navigation.domains.title",
    //component: () => <Redirect to={ROUTE_DOMAINS_REGISTER} />,
    groups: [
      {
        title: "navigation.domains.register_and_transfer",
        routes: [
          {
            path: ROUTE_DOMAINS_REGISTER,
            component: DomainsRegisterPage,
            exact: true,
            title: "navigation.domains.register_domain.title",
            description: "navigation.domains.register_domain.description"
          },
          {
            path: ROUTE_DOMAINS_TRANSFER,
            component: DomainsTransferPage,
            exact: true,
            title: "navigation.domains.transfer_domain.title",
            description: "navigation.domains.transfer_domain.description"
          }
        ]
      },
      {
        title: "navigation.domains.prices_and_services",
        routes: [
          {
            path: ROUTE_DOMAINS_PRICES,
            component: DomainsPricesPage,
            exact: true,
            title: "navigation.domains.domain_prices.title",
            description: "navigation.domains.domain_prices.description"
          },
          {
            path: ROUTE_DOMAINS_NEW_TLDS,
            component: DomainsNewTLDsPage,
            exact: true,
            title: "navigation.domains.new_tlds.title",
            description: "navigation.domains.new_tlds.description"
          }
        ]
      },
      {
        title: "",
        routes: [
          {
            path: ROUTE_DOMAINS_BULK,
            component: DomainsBulkOptions,
            exact: true,
            title: "navigation.domains.bulk_options.title",
            description: "navigation.domains.bulk_options.description"
          },
          {
            path: ROUTE_DOMAINS_WHOIS,
            component: DomainsWhoIs,
            exact: true,
            title: "navigation.domains.domain_whois.title",
            description: "navigation.domains.domain_whois.description"
          }
        ]
      }
    ]
  },
  {
    path: ROUTE_HOSTING,
    exact: true,
    title: "navigation.hosting.title",
    //component: () => <Redirect to={ROUTE_HOSTING_WEB_HOSTING} />,
    groups: [
      {
        title: "navigation.hosting.hosting",
        routes: [
          {
            path: ROUTE_HOSTING_WEB_HOSTING,
            component: HostingWebPage,
            exact: true,
            title: "navigation.hosting.web_hosting.title",
            description: "navigation.hosting.web_hosting.description"
          },
          {
            path: ROUTE_HOSTING_BUSINESS_HOSTING,
            component: HostingBusinessPage,
            exact: true,
            title: "navigation.hosting.business_hosting.title",
            description: "navigation.hosting.business_hosting.description"
          }
        ]
      },
      {
        title: "",
        routes: [
          {
            path: ROUTE_HOSTING_WINDOWS_HOSTING,
            component: HostingWindowsPage,
            exact: true,
            title: "navigation.hosting.windows_hosting.title",
            description: "navigation.hosting.windows_hosting.description"
          },
          {
            path: ROUTE_HOSTING_WORDPRESS_HOSTING,
            component: HostingWordpressPage,
            exact: true,
            title: "navigation.hosting.wordpress_hosting.title",
            description: "navigation.hosting.wordpress_hosting.description"
          }
        ]
      }
      // {
      //   title: "",
      //   routes: [
      //     {
      //       path: ROUTE_HOSTING_DIRECTADMIN_HOSTING,
      //       component: HostingDirectAdminPage,
      //       exact: true,
      //       title: "navigation.hosting.directadmin_hosting.title",
      //       description: "navigation.hosting.directadmin_hosting.description"
      //     }
      //   ]
      // }
    ]
  },
  {
    path: ROUTE_SERVERS,
    exact: true,
    title: "navigation.servers.title",
    //component: () => <Redirect to={ServersDedicatedPage} />,
    groups: [
      {
        title: "navigation.servers.servers",
        routes: [
          {
            path: ROUTE_SERVERS_DEDICATED,
            component: ServersDedicatedPage,
            exact: true,
            title: "navigation.servers.dedicated_servers.title",
            description: "navigation.servers.dedicated_servers.description"
          },
          // {
          //   path: ROUTE_SERVERS_CLOUD,
          //   component: ServersCloudPage,
          //   exact: true,
          //   title: "navigation.servers.cloud_hosting.title",
          //   description: "navigation.servers.cloud_hosting.description"
          // }
        ]
      }
    ]
  },
  {
    path: ROUTE_CLOUD_SOLUTIONS,
    exact: true,
    title: "navigation.cloud-solutions.title",
    //component: () => <Redirect to={ServersDedicatedPage} />,
    groups: [
      {
        title: "navigation.cloud-solutions.managed-cloud",
        routes: [
          {
            path: ROUTE_CLOUD_SOLUTIONS_MANAGED_CLOUD,
            component: ManagedCloud,
            exact: true,
            title: "navigation.cloud-solutions.managed-cloud",
            description: "navigation.cloud-solutions.description"
          }
        ]
      }
    ]
  },
  {
    path: ROUTE_WEB_DESIGN,
    exact: true,
    title: "navigation.webdesign.title",
    //component: () => <Redirect to={WebDesignPersonalizedPage} />,
    groups: [
      {
        title: "navigation.webdesign.webdesign",
        routes: [
          {
            path: ROUTE_WEB_DESIGN_PREMIUM,
            component: WebDesignPremiumPage,
            exact: true,
            title: "navigation.webdesign.mkhost_premium.title",
            description: "navigation.webdesign.mkhost_premium.description"
          },
          {
            path: ROUTE_WEB_DESIGN_PERSONALIZED,
            component: WebDesignPersonalizedPage,
            exact: true,
            title: "navigation.webdesign.personalized_solutions.title",
            description:
              "navigation.webdesign.personalized_solutions.description"
          },

        ]
      },
      {
        title: "",
        routes: [
          {
            path: ROUTE_WEB_DESIGN_ONLINE_SHOP,
            component: WebDesignOnlineShop,
            exact: true,
            title: "navigation.webdesign.online-shops.title",
            description: "navigation.webdesign.online-shops.descriptions"
          }
        ]
      }
    ]
  },
  {
    path: ROUTE_ADDONS,
    exact: true,
    title: "navigation.addons.title",
    //component: () => <Redirect to={AddonsPremiumSupportPage} />,
    groups: [
      {
        title: "navigation.addons.addons",
        routes: [
          {
            path: ROUTE_ADDONS_SLL,
            component: AddonsSSLPage,
            exact: true,
            title: "navigation.addons.ssl.title",
            description: "navigation.addons.ssl.description"
          },
          {
            path: ROUTE_ADDONS_SUPPORT,
            component: AddonsPremiumSupportPage,
            exact: true,
            title: "navigation.addons.premium_support.title",
            description: "navigation.addons.premium_support.description"
          },
          {
            path: ROUTE_AWS_SERVICES,
            component: AWSCloudLandingPage,
            exact: true,
            title: "navigation.addons.aws_cloud_page.title",
            description: "navigation.addons.aws_cloud_page.description",
            hideOn: 'mk'
          }
        ]
      }
    ]
  },
  {
    path: ROUTE_OTHER,
    exact: true,
    title: "navigation.other.title",
    footerTitle: "navigation.other.footerTitle",
    //component: () => <Redirect to={OtherAboutUsPage} />,
    groups: [
      {
        title: "navigation.other.mkhost",
        routes: [
          {
            path: ROUTE_OTHER_ABOUT_US,
            component: OtherAboutUsPage,
            exact: true,
            title: "navigation.other.about_us.title",
            description: "navigation.other.about_us.description"
          },
          {
            path: ROUTE_OTHER_OUR_BRAND,
            component: OtherBrandPage,
            exact: true,
            title: "navigation.other.our_brand.title",
            description: "navigation.other.our_brand.description"
          },
          {
            path: ROUTE_OTHER_FIRST_WEBSITE,
            component: OtherFirstWebsitePage,
            exact: true,
            title: "navigation.other.home.title",
            description: "navigation.other.home.description"
          }
        ]
      },
      {
        title: "",
        routes: [
          {
            path: ROUTE_OTHER_CONTACT,
            component: OtherContactPage,
            exact: true,
            title: "navigation.other.contact.title",
            description: "navigation.other.contact.description"
          },
          {
            path: ROUTE_OTHER_LEGAL,
            component: OtherLegalPage,
            exact: true,
            title: "navigation.other.legal.title",
            description: "navigation.other.legal.description"
          },
          {
            path: ROUTE_OTHER_HOSTING_CLOUD_CONSULTATION,
            component: OtherHostingCloudConsultationPage,
            exact: true,
            title: "navigation.other.hosting-cloud-consultation.title",
            description: "navigation.other.hosting-cloud-consultation.description"
          }
        ]
      },
      {
        title: "",
        routes: [
          {
            path: ROUTE_OTHER_CAREERS,
            component: OtherCareersPage,
            exact: true,
            title: "navigation.other.careers.title",
            description: "navigation.other.careers.description"
          },
          {
            link: "https://blog.mkhost.com.mk",
            exact: true,
            title: "navigation.other.blog.title",
            description: "navigation.other.blog.description"
          },
          {
            path: ROUTE_OTHER_REFERRAL,
            component: ReferralLandingPage,
            exact: true,
            title: "navigation.other.referral.title",
            description: "navigation.other.referral.description"
          }
          /*  {
              path: ROUTE_OTHER_WEB_SOLUTION,
              component: OtherWebSolution,
              exact: true,
              title: "navigation.other.other-web-solution.title",
              description: "navigation.other-web-solution.description"
            }
            */


        ]
      },
      {
        title: "navigation.other.help",
        routes: [
          {
            path: ROUTE_OTHER_KNOWLEDGEBASE,
            component: OtherKnowledgeBasePage,
            exact: true,
            title: "navigation.other.knowledgebase.title",
            description: "navigation.other.knowledgebase.description"
          },
          // {
          //   path: ROUTE_OTHER_YOUNG_ENTERPRENEURS,
          //   component: OtherYoungEnterpreneursPage,
          //   exact: true,
          //   title: "navigation.other.young_enterpreneurs.title",
          //   description: "navigation.other.young_enterpreneurs.description"
          // } //,
          // {
          //   path: ROUTE_OTHER_FREE_EBOOK,
          //   component: OtherFreeEbookPage,
          //   exact: true,
          //   title: "navigation.other.free-ebook.title",
          //   description: "navigation.other.free-ebook.description"
          // },

          {
            path: ROUTE_EMAIL_TUTORIALS,
            component: AllChoices,
            exact: true,
            title: "navigation.other.email-tutorials.title",
            description: "navigation.other.email-tutorials.description"
          },
          // {
          //   path: ROUTE_OTHER_WEBINAR_FIRST_WEBSITE,
          //   // component: OtherWebinarFirstWebsitePage,
          //   component: () => <Redirect to={ROUTE_404} />,
          //   exact: true,
          //   title: "navigation.other.webinar-first-website.title",
          //   description: "navigation.other.webinar-first-website.description"
          // },
        ]
      }
    ]
  }
];
//
// these are additional routes that are need but should not appear
// in the navbar
export const additionalRoutes = [
  // {
  //   path: ROUTE_DOMAINS_REGISTER,
  //   component: DomainsRegisterPage,
  //   exact: true
  // },
  // {
  //   path: ROUTE_DOMAINS_TRANSFER,
  //   component: DomainsTransferPage,
  //   exact: true
  // },
  // {
  //   path: ROUTE_DOMAINS_PRICES,
  //   component: DomainsPricesPage,
  //   exact: true
  // },
  // {
  //   path: ROUTE_DOMAINS_WHOIS,
  //   component: DomainsWhoIs,
  //   exact: true
  // },
  {
    path: ROUTE_OTHER_CAREERS_JOB_POST,
    component: OtherJobPostPage,
    exact: true
  },
  {
    path: ROUTE_OTHER_KNOWLEDGEBASE_CATEGORY,
    component: OtherKnowledgeBaseCategoryPage,
    exact: true
  },
  {
    path: ROUTE_OTHER_KNOWLEDGEBASE_ARTICLE,
    component: OtherKnowledgeBaseArticlePage,
    exact: false
  },
  {
    path: ROUTE_OTHER_CONTROL_PANEL,
    component: OtherControlPanelPage,
    exact: true
  },
  {
    path: ROUTE_TICKET_CREATED,
    component: TicketCreated,
    exact: true
  },
  {
    path: ROUTE_OFFER_SENT,
    component: OfferSent,
    exact: true
  },
  {
    path: ROUTE_WEBSOLUTION_SENT,
    component: WebSolutionSent,
    exact: true
  },
  {
    path: ROUTE_PRIZE_SENT,
    component: WinPrizeSent,
    exact: true
  },
  // {
  //   path: ROUTE_OTHER_FREE_EBOOK_THANK_YOU,
  //   component: OtherFreeEbookThankYouPage,
  //   exact: true
  // },
  {
    path: ROUTE_EMAIL_TUTORIALS,
    component: AllChoices,
    exact: true,
    title: "navigation.other.email-tutorials.title",
    description: "navigation.other.email-tutorials.description"
  },
  {
    path: ROUTE_EMAIL_TUTORIALS_ANDROID,
    component: AndroidPage,
    exact: true
  },
  {
    path: ROUTE_EMAIL_TUTORIALS_OUTLOOK,
    component: OutlookPage,
    exact: true
  },
  {
    path: ROUTE_EMAIL_TUTORIALS_THUNDERBIRD,
    component: ThunderbirdPage,
    exact: true
  },
  {
    path: ROUTE_EMAIL_TUTORIALS_MACOS,
    component: MacOsPage,
    exact: true
  },
  {
    path: ROUTE_EMAIL_TUTORIALS_IOS,
    component: IosPage,
    exact: true
  },
  {
    path: ROUTE_EMAIL_TUTORIALS_ANDROID_IMAP,
    component: AndroidPageImap,
    exact: true
  },
  {
    path: ROUTE_EMAIL_TUTORIALS_ANDROID_POP,
    component: AndroidPagePop,
    exact: true
  },
  {
    path: ROUTE_EMAIL_TUTORIALS_OUTLOOK_POP,
    component: OutlookPagePop,
    exact: true
  },
  {
    path: ROUTE_EMAIL_TUTORIALS_OUTLOOK_IMAP,
    component: OutlookPageImap,
    exact: true
  },
  {
    path: ROUTE_EMAIL_TUTORIALS_THUNDERBIRD_IMAP,
    component: ThunderbirdPageImap,
    exact: true
  },
  {
    path: ROUTE_EMAIL_TUTORIALS_THUNDERBIRD_POP,
    component: ThunderbirdPagePop,
    exact: true
  },
  {
    path: ROUTE_EMAIL_TUTORIALS_MACOS_IMAP,
    component: MacOsImapPage,
    exact: true
  },
  {
    path: ROUTE_EMAIL_TUTORIALS_MACOS_POP,
    component: MacOsPopPage,
    exact: true
  },
  {
    path: ROUTE_EMAIL_TUTORIALS_IOS_IMAP,
    component: IosImapPage,
    exact: true
  },
  {
    path: ROUTE_EMAIL_TUTORIALS_IOS_POP,
    component: IosPopPage,
    exact: true
  },
  {
    path: ROUTE_EMAIL_TUTORIALS_ERROR,
    component: ErrorPage,
    exact: true
  },
  {
    path: ROUTE_OTHER_HOSTING_CLOUD_CONSULTATION_THANK_YOU,
    component: OtherHostingCloudConsultationThankYouPage,
    exact: true
  },
  {
    path: ROUTE_OTHER_WEBINAR_FIRST_WEBSITE_THANK_YOU,
    component: OtherWebinarFirstWebsiteThankYouPage,
    exact: true
  },

  {
    path: "/",
    component: () => <Redirect to={ROUTE_HOME} />,
    exact: true
  }
];

export function compileRoute(route, values) {
  return reverse(route, values);
}
